import { z } from 'zod'

export const SignUpFormSchema = z
  .object({
    targetLanguage: z.string().min(1, 'Language is required'),
    nativeLanguage: z.string().min(1, 'Native language is required'),
    email: z.string().email('Invalid email').min(1, 'Email is required'),
    password: z.string().min(6, 'Password must be at least 6 characters'),
    confirmPassword: z
      .string()
      .min(6, 'Password must be at least 6 characters'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  })

export const LoginSchema = z.object({
  email: z.string().email('Invalid email').min(1, 'Email is required'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
})

export const ForgotPasswordSchema = z.object({
  email: z.string().email('Invalid email').min(1, 'Email is required'),
})

export const LanguageSettingsSchema = z.object({
  nativeLanguageId: z.string(),
  targetLanguageId: z.string(),
})

export const UserLanguageSettingsSchema = z.object({
  targetLanguageId: z.string(),
  proficiencyLevel: z.string(),
  aiBilingualMode: z.string(),
  aiFormalityMode: z.string(),
  aiCorrectionMode: z.string(),
  aiTalkingSpeed: z.string(),
})

export const PersonalInformationSchema = z.object({
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
})

export const UpdatePasswordSchema = z
  .object({
    password: z.string().min(6, 'Password must be at least 6 characters'),
    passwordRepeat: z.string().min(6, 'Password must be at least 6 characters'),
  })
  .refine((data) => data.password === data.passwordRepeat, {
    path: ['passwordRepeat'],
    message: 'Passwords do not match',
  })
